<template>
  <div class="page-container">
    <nav-bar title="在线报名" :fixed="true" id="navbar"></nav-bar>
    <div class="form-area" v-if="!isSignupSuccess">
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        请您如实填写，进入教学平台学习需要验证报名信息！
      </van-notice-bar>
      <van-form @submit="onSubmit">
        <van-field
          v-model="username"
          name="username"
          label="真实姓名"
          placeholder="真实姓名"
          :rules="[{ required: true, message: '请填写真实姓名' }]"
        />
        <van-field
          v-model="certNum"
          name="certNum"
          label="身份证号"
          placeholder="身份证号"
          maxlength="18"
          :rules="[{ required: true, message: '请填写身份证号码' }]"
        />
        <van-field name="sex" label="性别">
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="tel"
          name="tel"
          type="tel"
          label="手机号码"
          placeholder="手机号码"
          maxlength="11"
          @input="onPhoneChange"
          :rules="[{ required: true, message: '请填写报名手机号码' }]"
        />
        <van-field
          v-model="sms"
          name="sms"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          maxlength="6"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="sendSmsCode"
              native-type="button"
              :disabled="isSmsSend"
              >{{ sendBtnText }}</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="fieldValue"
          name="fieldValue"
          is-link
          readonly
          label="报名车型"
          placeholder="请选择报名培训车型"
          @click="show = true"
        />
        <van-popup v-model="show" round position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="请选择报名培训车型"
            :options="options"
            @close="show = false"
            @finish="onFinish"
          />
        </van-popup>
        <van-cell
          title="课程名称"
          :value="planName"
          label="请您仔细确认课程名称无误"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <div class="checked-area" v-if="isSignupSuccess">
      <van-icon name="checked" color="#67C23A" size="80" />
      <div><span class="checked-msg">恭喜您，报名成功！</span></div>
      <div class="checked-plan">
        <van-cell-group title="报名信息" inset>
          <van-cell title="真实姓名" :value="username" />
          <van-cell title="身份证号" :value="certNum" />
          <van-cell title="手机号码" :value="tel" />
          <van-cell title="车型" :value="fieldValue" />
          <van-cell title="课程" :value="planName" />
          <div style="margin: 16px">
            <van-button
              round
              block
              type="info"
              native-type="button"
              @click="gotoStudy"
              >点击进入在线教育平台，开始学习吧！</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Toast } from "vant";
import {
  getStudyPlanInfo,
  getPhoneCode,
  checkPhoneCode,
  signup,
} from "../../api/user";
export default {
  name: "signup-wuhai",
  components: { NavBar },
  data() {
    return {
      isSignupSuccess: false,
      // 是否已经发送了验证码
      isSmsSend: false,
      // 文本
      sendBtnText: "发送验证码",
      // 计时器对象
      timer: null,
      // 倒数60秒
      counter: 60,
      tel: "",
      sms: "",
      username: "",
      certNum: "",
      sex: '1',
      show: false,
      fieldValue: "",
      cascaderValue: "",
      categroy: "",
      pCategory: "",
      cityCode: "150300",
      planName: "",
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [
        {
          text: "继续教育培训",
          value: "115",
          children: [
            { text: "客运", value: "116" },
            { text: "货运", value: "117" },
            { text: "出租车", value: "118" },
            { text: "危险品", value: "120" },
            { text: "出租车补学", value: "141" },
          ],
        },
        {
          text: "计分教育培训",
          value: "136",
          children: [
            { text: "客运", value: "137" },
            { text: "货运", value: "138" },
            { text: "危险品", value: "139" },
            { text: "出租车", value: "140" },
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {
    getStudyPlanInfo(cityCode, categoryId) {
      getStudyPlanInfo({
        cityCode,
        categoryId,
      })
        .then((res) => {
          this.planName = res.planName;
        })
        .catch(() => {});
    },
    onSubmit(values) {
      //console.log("submit", values);
      if (values.fieldValue == "") {
        Toast.fail("请您选择报名车型");
        return false;
      }
      // 验证码校验
      checkPhoneCode({
        mobile: values.tel,
        code: values.sms,
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            // 报名
            signup({
              studentName: values.username,
              certNum: values.certNum,
              sex: values.sex,
              mobile: values.tel,
              categoryId: this.categroy,
              cityCode: this.cityCode
            })
              .then(() => {
                this.isSignupSuccess = true;
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    onFinish({ selectedOptions }) {
      this.pCategory = selectedOptions[0].value;
      this.categroy = selectedOptions[selectedOptions.length - 1].value;
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
      //this.categroy = selectedOptions.map((option) => option.value).join("/");
      //console.log(this.fieldValue);
      //console.log(this.categroy);
      this.getStudyPlanInfo(this.cityCode, this.categroy);
    },
    sendSmsCode() {
      // 判断手机号是否已经输入
      if (!this.tel) {
        Toast.fail("请输入手机号");
        return false;
      }
      // 判断手机号是否符合要求
      if (this.tel.length !== 11) {
        Toast.fail("请输入11位手机号");
        return false;
      }
      // 调用接口，发送短信验证码
      getPhoneCode({ mobile: this.tel, signup: '1' }).then((res) => {
        if (res.data.smsCode) {
          this.sms = res.data.smsCode;
        }
        // 将按钮禁用，防止再次点击
        this.isSmsSend = true;
        // 开始倒计时，60s之后才能再次点击
        this.countDown(); // 这里实现倒计时的功能，文章下面开始介绍
      });
    },
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "发送验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 60;
        this.timer = null;
      }
    },
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `(${this.counter}秒)后重新发送`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    onPhoneChange() {
      this.reset();
    },
    gotoStudy() {
      var categoryId = this.pCategory;
      if (categoryId === 115) {
        //继续教育
        window.location.href = "/index.html#/?appNo=APP02";
      } else if (categoryId === 136) {
        // 计时教育
        window.location.href = "/index.html#/?appNo=APP05";
      } else {
        // 默认
        window.location.href = "/index.html#/?appNo=APP02";
      }
    },
  },
};
</script>

<style scoped>
#navbar {
  background-color: #409eff;
}
#navbar /deep/ .van-ellipsis {
  color: #fff !important;
}
.form-area {
  margin-top: 46px;
}
.checked-area {
  margin-top: 90px;
  text-align: center;
}
.checked-msg {
  font-size: 14px;
  color: #67c23a;
}
.checked-plan {
  text-align: left;
  margin-top: 40px;
}
</style>
